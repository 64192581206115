import { createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, vModelSelect as _vModelSelect, withDirectives as _withDirectives, vModelText as _vModelText, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  class: "modal fade",
  ref: "submitButtonRef",
  id: "kt_modal_kpi",
  tabindex: "-1",
  "aria-hidden": "true"
}
const _hoisted_2 = { class: "modal-dialog modal-dialog-centered mw-650px" }
const _hoisted_3 = { class: "modal-content roundeda" }
const _hoisted_4 = {
  key: 0,
  class: "modal-body scroll-y mx-5 mx-xl-15 my-7"
}
const _hoisted_5 = {
  key: 0,
  class: "alert alert-danger"
}
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = {
  key: 1,
  class: "alert alert-success"
}
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { key: 1 }
const _hoisted_14 = { class: "row fv-row" }
const _hoisted_15 = { class: "col-6" }
const _hoisted_16 = {
  key: 0,
  class: "d-flex flex-column mb-7 fv-row"
}
const _hoisted_17 = { class: "fv-plugins-message-container" }
const _hoisted_18 = { class: "fv-help-block" }
const _hoisted_19 = {
  key: 1,
  class: "d-flex flex-column mb-7 fv-row"
}
const _hoisted_20 = {
  key: 2,
  class: "d-flex flex-column mb-7 fv-row"
}
const _hoisted_21 = {
  key: 3,
  class: "d-flex flex-column mb-7 fv-row"
}
const _hoisted_22 = { class: "fv-plugins-message-container" }
const _hoisted_23 = { class: "fv-help-block" }
const _hoisted_24 = {
  key: 4,
  class: "d-flex flex-column mb-7 fv-row"
}
const _hoisted_25 = { class: "fv-plugins-message-container" }
const _hoisted_26 = { class: "fv-help-block" }
const _hoisted_27 = { class: "col-6" }
const _hoisted_28 = {
  key: 0,
  class: "d-flex flex-column mb-7 fv-row"
}
const _hoisted_29 = { class: "fv-plugins-message-container" }
const _hoisted_30 = { class: "fv-help-block" }
const _hoisted_31 = {
  key: 1,
  class: "d-flex flex-column mb-7 fv-row"
}
const _hoisted_32 = { class: "fv-plugins-message-container" }
const _hoisted_33 = { class: "fv-help-block" }
const _hoisted_34 = { class: "d-flex flex-column mb-7 fv-row" }
const _hoisted_35 = { class: "fv-plugins-message-container" }
const _hoisted_36 = { class: "fv-help-block" }
const _hoisted_37 = {
  key: 2,
  class: "d-flex flex-column mb-7 fv-row"
}
const _hoisted_38 = ["value"]
const _hoisted_39 = { class: "fv-plugins-message-container" }
const _hoisted_40 = { class: "fv-help-block" }
const _hoisted_41 = {
  key: 3,
  class: "d-flex flex-column mb-7 fv-row"
}
const _hoisted_42 = {
  key: 4,
  class: "d-flex flex-column mb-7 fv-row"
}
const _hoisted_43 = ["value"]
const _hoisted_44 = {
  key: 0,
  value: "add_new"
}
const _hoisted_45 = {
  key: 0,
  class: "row"
}
const _hoisted_46 = { class: "col-9" }
const _hoisted_47 = { class: "col-3" }
const _hoisted_48 = {
  key: 5,
  class: "d-flex flex-column mb-7 fv-row"
}
const _hoisted_49 = ["value"]
const _hoisted_50 = {
  key: 0,
  value: "add_new"
}
const _hoisted_51 = {
  key: 0,
  class: "row"
}
const _hoisted_52 = { class: "col-9" }
const _hoisted_53 = { class: "col-3" }
const _hoisted_54 = {
  key: 6,
  class: "d-flex flex-column mb-7 fv-row"
}
const _hoisted_55 = { class: "fv-plugins-message-container" }
const _hoisted_56 = { class: "fv-help-block" }
const _hoisted_57 = {
  key: 7,
  class: "d-flex flex-column mb-7 fv-row"
}
const _hoisted_58 = { class: "fv-plugins-message-container" }
const _hoisted_59 = { class: "fv-help-block" }
const _hoisted_60 = { class: "text-center pt-15" }
const _hoisted_61 = {
  type: "reset",
  id: "kt_modal_bin_cancel",
  class: "btn btn-white me-3",
  ref: "cancelRef"
}
const _hoisted_62 = {
  ref: "submitButtonRef",
  type: "submit",
  id: "kt_modal_bin_submit",
  class: "btn btn-primary"
}
const _hoisted_63 = {
  key: 1,
  class: "text-danger"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createCommentVNode("begin::Modal - New Card"),
    _createElementVNode("div", _hoisted_1, [
      _createCommentVNode("begin::Modal dialog"),
      _createElementVNode("div", _hoisted_2, [
        _createCommentVNode("begin::Modal content"),
        _createElementVNode("div", _hoisted_3, [
          _createCommentVNode("begin::Modal header"),
          _cache[54] || (_cache[54] = _createStaticVNode("<div class=\"modal-header bg-primary\" data-v-1c37df5c><!--begin::Modal title--><h4 class=\"ms-3 text-white text-uppercase\" data-v-1c37df5c> Add Devices to Platform </h4><!--end::Modal title--><!--begin::Close--><div class=\"btn btn-sm btn-icon btn-active-color-primary\" data-bs-dismiss=\"modal\" data-v-1c37df5c><span class=\"svg-icon svg-icon-1\" data-v-1c37df5c><i class=\"bi bi-x-circle-fill light-text fs-3\" style=\"color:white;\" data-v-1c37df5c></i></span></div><!--end::Close--></div>", 1)),
          _createCommentVNode("end::Modal header"),
          _createCommentVNode("begin::Modal body"),
          (_ctx.customer_id > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createCommentVNode(" ErrorMessage "),
                (_ctx.addToPlatformResp && _ctx.addToPlatformResp.error)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      _createElementVNode("a", {
                        class: "close",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.hideAlert())),
                        "aria-label": "Close"
                      }, _cache[22] || (_cache[22] = [
                        _createElementVNode("i", { class: "fas fa-times-circle" }, "close", -1 /* HOISTED */)
                      ])),
                      (_ctx.isObject(_ctx.addToPlatformResp.data.error.message))
                        ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.addToPlatformResp.data.error, (item, index) => {
                              return (_openBlock(), _createElementBlock("span", { key: index }, [
                                item
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(item[0]), 1 /* TEXT */))
                                  : _createCommentVNode("v-if", true)
                              ]))
                            }), 128 /* KEYED_FRAGMENT */))
                          ]))
                        : _createCommentVNode("v-if", true),
                      (!_ctx.isObject(_ctx.addToPlatformResp.data.error.message))
                        ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.addToPlatformResp.error.message), 1 /* TEXT */))
                        : _createCommentVNode("v-if", true)
                    ]))
                  : _createCommentVNode("v-if", true),
                (_ctx.addToPlatformResp && _ctx.addToPlatformResp.success)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                      _createElementVNode("a", {
                        class: "close",
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.hideAlert())),
                        "aria-label": "Close"
                      }, _cache[23] || (_cache[23] = [
                        _createElementVNode("i", { class: "fas fa-times-circle" }, null, -1 /* HOISTED */)
                      ])),
                      (_ctx.addToPlatformResp.success)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_10, [
                            _createTextVNode(_toDisplayString(_ctx.addToPlatformResp.message) + " ", 1 /* TEXT */),
                            (_ctx.addToPlatformResp.data.added)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                                  _createElementVNode("b", null, "Added IDs (" + _toDisplayString(_ctx.addToPlatformResp.data.added.length) + "):", 1 /* TEXT */),
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.addToPlatformResp.data.added, (device, index) => {
                                    return (_openBlock(), _createElementBlock("span", { key: index }, [
                                      _createTextVNode(_toDisplayString(device.variant_id) + " : : " + _toDisplayString(device.message) + " ", 1 /* TEXT */),
                                      _cache[24] || (_cache[24] = _createElementVNode("br", null, null, -1 /* HOISTED */))
                                    ]))
                                  }), 128 /* KEYED_FRAGMENT */)),
                                  _cache[28] || (_cache[28] = _createElementVNode("br", null, null, -1 /* HOISTED */)),
                                  _createElementVNode("b", null, "Updated IDs (" + _toDisplayString(_ctx.addToPlatformResp.data.updated.length) + "):", 1 /* TEXT */),
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.addToPlatformResp.data.updated, (device, index) => {
                                    return (_openBlock(), _createElementBlock("span", { key: index }, [
                                      _createTextVNode(_toDisplayString(device.variant_id) + " : " + _toDisplayString(device.message) + " ", 1 /* TEXT */),
                                      _cache[25] || (_cache[25] = _createElementVNode("br", null, null, -1 /* HOISTED */))
                                    ]))
                                  }), 128 /* KEYED_FRAGMENT */)),
                                  _cache[29] || (_cache[29] = _createElementVNode("br", null, null, -1 /* HOISTED */)),
                                  _createElementVNode("b", null, "Skipped IDs (" + _toDisplayString(_ctx.addToPlatformResp.data.skipped.length) + "):", 1 /* TEXT */),
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.addToPlatformResp.data.skipped, (device, index) => {
                                    return (_openBlock(), _createElementBlock("span", { key: index }, [
                                      _createTextVNode(_toDisplayString(device.variant_id) + " : " + _toDisplayString(device.message) + " ", 1 /* TEXT */),
                                      _cache[26] || (_cache[26] = _createElementVNode("br", null, null, -1 /* HOISTED */))
                                    ]))
                                  }), 128 /* KEYED_FRAGMENT */)),
                                  _cache[30] || (_cache[30] = _createElementVNode("br", null, null, -1 /* HOISTED */)),
                                  _createElementVNode("b", null, "Error messages (" + _toDisplayString(_ctx.addToPlatformResp.data.errors.length) + "):", 1 /* TEXT */),
                                  _cache[31] || (_cache[31] = _createElementVNode("br", null, null, -1 /* HOISTED */)),
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.addToPlatformResp.data.errors, (device, index) => {
                                    return (_openBlock(), _createElementBlock("span", { key: index }, [
                                      _createTextVNode(" Device Id " + _toDisplayString(device.deviceId) + ": ", 1 /* TEXT */),
                                      (_ctx.isObject(device.message))
                                        ? (_openBlock(), _createElementBlock("span", _hoisted_12, [
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.addToPlatformResp.message, (device, index) => {
                                              return (_openBlock(), _createElementBlock("div", { key: index }, _toDisplayString(_ctx.item[0]), 1 /* TEXT */))
                                            }), 128 /* KEYED_FRAGMENT */))
                                          ]))
                                        : _createCommentVNode("v-if", true),
                                      (!_ctx.isObject(device.message))
                                        ? (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(device.message), 1 /* TEXT */))
                                        : _createCommentVNode("v-if", true),
                                      _cache[27] || (_cache[27] = _createElementVNode("br", null, null, -1 /* HOISTED */))
                                    ]))
                                  }), 128 /* KEYED_FRAGMENT */))
                                ]))
                              : _createCommentVNode("v-if", true)
                          ]))
                        : _createCommentVNode("v-if", true)
                    ]))
                  : _createCommentVNode("v-if", true),
                _createCommentVNode(" End ErrorMessage "),
                _createCommentVNode("begin::Form"),
                _createVNode(_component_Form, {
                  id: "kt_modal_bin_form",
                  class: "form",
                  onSubmit: _ctx.submit,
                  "validation-schema": _ctx.cardData.sensor_types_id == 1
          ? _ctx.validationSchema
          : _ctx.validationSchemaTrack
          
                }, {
                  default: _withCtx(() => [
                    _createCommentVNode("begin::Input group"),
                    _createCommentVNode(" <div class=\"row mb-12\"> "),
                    _createCommentVNode("begin::Col"),
                    _createCommentVNode(" <div class=\"col-md-6 fv-row\"> "),
                    _createCommentVNode("begin::Row"),
                    _createElementVNode("div", _hoisted_14, [
                      _createCommentVNode("begin::Col"),
                      _createElementVNode("div", _hoisted_15, [
                        (_ctx.cardData.sensor_types_id == 1)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                              _createCommentVNode("begin::Label"),
                              _cache[32] || (_cache[32] = _createElementVNode("label", { class: "d-flex align-items-center fs-6 fw-bold form-label mb-2" }, [
                                _createElementVNode("span", { class: "required" }, "Pickup Threshold "),
                                _createElementVNode("i", {
                                  class: "fas fa-exclamation-circle ms-2 fs-7",
                                  "data-bs-toggle": "tooltip",
                                  title: "Specify Bin's Pickup Threshold"
                                })
                              ], -1 /* HOISTED */)),
                              _createCommentVNode("end::Label"),
                              _createVNode(_component_Field, {
                                type: "number",
                                class: "form-control form-control-solid border border-primary bg-light rounded-2",
                                placeholder: "Threshold",
                                name: "pickup_threshold",
                                modelValue: _ctx.cardData.pickup_threshold,
                                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.cardData.pickup_threshold) = $event))
                              }, null, 8 /* PROPS */, ["modelValue"]),
                              _createElementVNode("div", _hoisted_17, [
                                _createElementVNode("div", _hoisted_18, [
                                  _createVNode(_component_ErrorMessage, { name: "pickup_threshold" })
                                ])
                              ])
                            ]))
                          : _createCommentVNode("v-if", true),
                        (_ctx.checkKPI && _ctx.cardData.sensor_types_id == 1)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                              _createCommentVNode("begin::Label"),
                              _cache[33] || (_cache[33] = _createElementVNode("label", { class: "d-flex align-items-center fs-6 fw-bold form-label mb-2" }, [
                                _createElementVNode("span", null, "Weight Per Commodity")
                              ], -1 /* HOISTED */)),
                              _createCommentVNode("end::Label"),
                              _createVNode(_component_Field, {
                                type: "text",
                                class: "form-control form-control-solid border border-primary bg-light rounded-2",
                                placeholder: "Weight Per Commodity",
                                name: "weight_per_commodity",
                                modelValue: _ctx.cardData.weight_per_commodity,
                                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.cardData.weight_per_commodity) = $event))
                              }, null, 8 /* PROPS */, ["modelValue"])
                            ]))
                          : _createCommentVNode("v-if", true),
                        (_ctx.checkKPI && _ctx.cardData.sensor_types_id == 1)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                              _createCommentVNode("begin::Label"),
                              _cache[34] || (_cache[34] = _createElementVNode("label", { class: "d-flex align-items-center fs-6 fw-bold form-label mb-2" }, [
                                _createElementVNode("span", null, "Bin Capacity")
                              ], -1 /* HOISTED */)),
                              _createCommentVNode("end::Label"),
                              _createVNode(_component_Field, {
                                type: "text",
                                class: "form-control form-control-solid border border-primary bg-light rounded-2",
                                placeholder: "Bin Capacity",
                                name: "bin_capacity",
                                modelValue: _ctx.cardData.bin_capacity,
                                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.cardData.bin_capacity) = $event))
                              }, null, 8 /* PROPS */, ["modelValue"])
                            ]))
                          : _createCommentVNode("v-if", true),
                        (_ctx.cardData.sensor_types_id == 2)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                              _createCommentVNode("begin::Label"),
                              _cache[35] || (_cache[35] = _createElementVNode("label", { class: "d-flex align-items-center fs-6 fw-bold form-label mb-2" }, [
                                _createElementVNode("span", { class: "required" }, "Default Radius"),
                                _createElementVNode("i", {
                                  class: "fas fa-exclamation-circle ms-2 fs-7",
                                  "data-bs-toggle": "tooltip",
                                  title: "Specify default radius"
                                })
                              ], -1 /* HOISTED */)),
                              _createCommentVNode("end::Label"),
                              _createVNode(_component_Field, {
                                type: "number",
                                class: "form-control form-control-solid border border-primary bg-light rounded-2",
                                placeholder: "ex. 500",
                                name: "default_radius",
                                modelValue: _ctx.cardData.default_radius,
                                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.cardData.default_radius) = $event))
                              }, null, 8 /* PROPS */, ["modelValue"]),
                              _createElementVNode("div", _hoisted_22, [
                                _createElementVNode("div", _hoisted_23, [
                                  _createVNode(_component_ErrorMessage, { name: "default_radius" })
                                ])
                              ])
                            ]))
                          : _createCommentVNode("v-if", true),
                        (_ctx.cardData.sensor_types_id == 2)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                              _createCommentVNode("begin::Label"),
                              _cache[37] || (_cache[37] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "GPS Validity", -1 /* HOISTED */)),
                              _createCommentVNode("end::Label"),
                              _createCommentVNode("begin::Select"),
                              _createVNode(_component_Field, {
                                name: "gps_validity",
                                "data-control": "select2",
                                "data-hide-search": "true",
                                "data-placeholder": "Select Type",
                                class: "form-select border border-primary form-select-solid",
                                as: "select",
                                modelValue: _ctx.cardData.gps_validity,
                                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.cardData.gps_validity) = $event))
                              }, {
                                default: _withCtx(() => _cache[36] || (_cache[36] = [
                                  _createElementVNode("option", { value: "" }, "Select Type", -1 /* HOISTED */),
                                  _createElementVNode("option", { value: "1" }, "Enable", -1 /* HOISTED */),
                                  _createElementVNode("option", { value: "0" }, "Disable", -1 /* HOISTED */)
                                ])),
                                _: 1 /* STABLE */
                              }, 8 /* PROPS */, ["modelValue"]),
                              _createElementVNode("div", _hoisted_25, [
                                _createElementVNode("div", _hoisted_26, [
                                  _createVNode(_component_ErrorMessage, { name: "sensor_types_id" })
                                ])
                              ]),
                              _createCommentVNode("end::Select")
                            ]))
                          : _createCommentVNode("v-if", true)
                      ]),
                      _createCommentVNode("end::Col"),
                      _createCommentVNode("begin::Col"),
                      _createElementVNode("div", _hoisted_27, [
                        (_ctx.cardData.sensor_types_id == 1)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
                              _createCommentVNode("begin::Label"),
                              _cache[38] || (_cache[38] = _createElementVNode("label", { class: "d-flex align-items-center fs-6 fw-bold form-label mb-2" }, [
                                _createElementVNode("span", { class: "required" }, "Bin Height"),
                                _createElementVNode("i", {
                                  class: "fas fa-exclamation-circle ms-2 fs-7",
                                  "data-bs-toggle": "tooltip",
                                  title: "Specify Bin Height"
                                })
                              ], -1 /* HOISTED */)),
                              _createCommentVNode("end::Label"),
                              _createVNode(_component_Field, {
                                type: "number",
                                class: "form-control form-control-solid border border-primary bg-light rounded-2",
                                placeholder: "ex. 100",
                                name: "bin_height",
                                modelValue: _ctx.cardData.bin_height,
                                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.cardData.bin_height) = $event))
                              }, null, 8 /* PROPS */, ["modelValue"]),
                              _createElementVNode("div", _hoisted_29, [
                                _createElementVNode("div", _hoisted_30, [
                                  _createVNode(_component_ErrorMessage, { name: "bin_height" })
                                ])
                              ])
                            ]))
                          : _createCommentVNode("v-if", true),
                        (_ctx.cardData.sensor_types_id == 2)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
                              _createCommentVNode("begin::Label"),
                              _cache[39] || (_cache[39] = _createElementVNode("label", { class: "d-flex align-items-center fs-6 fw-bold form-label mb-2" }, [
                                _createElementVNode("span", { class: "required" }, "Battery"),
                                _createElementVNode("i", {
                                  class: "fas fa-exclamation-circle ms-2 fs-7",
                                  "data-bs-toggle": "tooltip",
                                  title: "Specify Battery"
                                })
                              ], -1 /* HOISTED */)),
                              _createCommentVNode("end::Label"),
                              _createVNode(_component_Field, {
                                type: "number",
                                class: "form-control form-control-solid border border-primary bg-light rounded-2",
                                placeholder: "ex. 100",
                                name: "battery",
                                modelValue: _ctx.cardData.battery,
                                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.cardData.battery) = $event))
                              }, null, 8 /* PROPS */, ["modelValue"]),
                              _createElementVNode("div", _hoisted_32, [
                                _createElementVNode("div", _hoisted_33, [
                                  _createVNode(_component_ErrorMessage, { name: "battery" })
                                ])
                              ])
                            ]))
                          : _createCommentVNode("v-if", true),
                        _createElementVNode("div", _hoisted_34, [
                          _createCommentVNode("begin::Label"),
                          _cache[40] || (_cache[40] = _createElementVNode("label", { class: "d-flex align-items-center fs-6 fw-bold form-label mb-2" }, [
                            _createElementVNode("span", { class: "required" }, "Latitude"),
                            _createElementVNode("i", {
                              class: "fas fa-exclamation-circle ms-2 fs-7",
                              "data-bs-toggle": "tooltip",
                              title: "Specify Bin's Latitude"
                            })
                          ], -1 /* HOISTED */)),
                          _createCommentVNode("end::Label"),
                          _createVNode(_component_Field, {
                            type: "text",
                            class: "form-control form-control-solid border border-primary bg-light rounded-2",
                            placeholder: "Latitude",
                            name: "lat",
                            modelValue: _ctx.cardData.lat,
                            "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.cardData.lat) = $event))
                          }, null, 8 /* PROPS */, ["modelValue"]),
                          _createElementVNode("div", _hoisted_35, [
                            _createElementVNode("div", _hoisted_36, [
                              _createVNode(_component_ErrorMessage, { name: "lat" })
                            ])
                          ])
                        ]),
                        (_ctx.cardData.sensor_types_id == 1)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_37, [
                              _createCommentVNode("begin::Label"),
                              _cache[42] || (_cache[42] = _createElementVNode("label", { class: "d-flex align-items-center fs-6 fw-bold form-label mb-2" }, [
                                _createElementVNode("span", { class: "required" }, " Waste Type "),
                                _createElementVNode("i", {
                                  class: "fas fa-exclamation-circle ms-2 fs-7",
                                  "data-bs-toggle": "tooltip",
                                  title: "Specify Waste Type of Bin"
                                })
                              ], -1 /* HOISTED */)),
                              _createCommentVNode("end::Label"),
                              _createCommentVNode("begin::Select"),
                              _createVNode(_component_Field, {
                                name: "waste_type_id",
                                "data-control": "select2",
                                "data-hide-search": "true",
                                "data-placeholder": "Select Type",
                                class: "form-select border border-primary form-select-solid",
                                as: "select",
                                modelValue: _ctx.cardData.waste_type_id,
                                "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.cardData.waste_type_id) = $event))
                              }, {
                                default: _withCtx(() => [
                                  _cache[41] || (_cache[41] = _createElementVNode("option", { value: "" }, "Select Type", -1 /* HOISTED */)),
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.wasteType, (role, i) => {
                                    return (_openBlock(), _createElementBlock("option", {
                                      key: i,
                                      value: role.id
                                    }, _toDisplayString(role.name), 9 /* TEXT, PROPS */, _hoisted_38))
                                  }), 128 /* KEYED_FRAGMENT */))
                                ]),
                                _: 1 /* STABLE */
                              }, 8 /* PROPS */, ["modelValue"]),
                              _createElementVNode("div", _hoisted_39, [
                                _createElementVNode("div", _hoisted_40, [
                                  _createVNode(_component_ErrorMessage, { name: "waste_type_id" })
                                ])
                              ]),
                              _createCommentVNode("end::Select")
                            ]))
                          : _createCommentVNode("v-if", true),
                        (_ctx.checkKPI && _ctx.cardData.sensor_types_id == 1)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_41, [
                              _createCommentVNode("begin::Label"),
                              _cache[43] || (_cache[43] = _createElementVNode("label", { class: "d-flex align-items-center fs-6 fw-bold form-label mb-2" }, [
                                _createElementVNode("span", null, "Bin Type")
                              ], -1 /* HOISTED */)),
                              _createCommentVNode("end::Label"),
                              _createVNode(_component_Field, {
                                type: "text",
                                class: "form-control form-control-solid border border-primary bg-light rounded-2",
                                placeholder: "Bin Type",
                                name: "bin_type",
                                modelValue: _ctx.cardData.bin_type,
                                "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.cardData.bin_type) = $event))
                              }, null, 8 /* PROPS */, ["modelValue"])
                            ]))
                          : _createCommentVNode("v-if", true),
                        (_ctx.checkKPI && _ctx.cardData.sensor_types_id == 1)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_42, [
                              _createCommentVNode("begin::Label"),
                              _cache[45] || (_cache[45] = _createElementVNode("label", { class: "d-flex align-items-center fs-6 fw-bold form-label mb-2" }, [
                                _createElementVNode("span", null, "Select Group")
                              ], -1 /* HOISTED */)),
                              _createCommentVNode("end::Label"),
                              _createElementVNode("div", null, [
                                _withDirectives(_createElementVNode("select", {
                                  id: "dynamicSelectGroup",
                                  class: "form-select form-control form-control-solid border border-primary bg-light rounded-2",
                                  "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.cardData.group_id) = $event)),
                                  onChange: _cache[13] || (_cache[13] = 
//@ts-ignore
(...args) => (_ctx.groupHandleChange && _ctx.groupHandleChange(...args))),
                                  name: "group_id"
                                }, [
                                  _cache[44] || (_cache[44] = _createElementVNode("option", { value: "" }, "Select Group", -1 /* HOISTED */)),
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.groupList, (option, index) => {
                                    return (_openBlock(), _createElementBlock("option", {
                                      key: index,
                                      value: option.group_id
                                    }, _toDisplayString(option.group_name), 9 /* TEXT, PROPS */, _hoisted_43))
                                  }), 128 /* KEYED_FRAGMENT */)),
                                  (!_ctx.cardData.group_id)
                                    ? (_openBlock(), _createElementBlock("option", _hoisted_44, "Add New Option..."))
                                    : _createCommentVNode("v-if", true)
                                ], 544 /* NEED_HYDRATION, NEED_PATCH */), [
                                  [_vModelSelect, _ctx.cardData.group_id]
                                ]),
                                (_ctx.addingGroupOption)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_45, [
                                      _createElementVNode("div", _hoisted_46, [
                                        _withDirectives(_createElementVNode("input", {
                                          type: "text",
                                          class: "form-control form-control-solid border border-primary bg-light rounded-2 mt-2",
                                          "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.newGroup) = $event)),
                                          placeholder: "New Option"
                                        }, null, 512 /* NEED_PATCH */), [
                                          [_vModelText, _ctx.newGroup]
                                        ])
                                      ]),
                                      _createElementVNode("div", _hoisted_47, [
                                        _createElementVNode("button", {
                                          type: "button",
                                          onClick: _cache[15] || (_cache[15] = 
//@ts-ignore
(...args) => (_ctx.addNewGroup && _ctx.addNewGroup(...args))),
                                          class: "form-control add-new-button mt-2"
                                        }, "Go")
                                      ])
                                    ]))
                                  : _createCommentVNode("v-if", true)
                              ])
                            ]))
                          : _createCommentVNode("v-if", true),
                        (_ctx.checkKPI && _ctx.cardData.group_id && _ctx.cardData.group_id != 'add_new' && _ctx.cardData.sensor_types_id == 1 && _ctx.cardData.lat && _ctx.cardData.lng)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_48, [
                              _createCommentVNode("begin::Label"),
                              _cache[47] || (_cache[47] = _createElementVNode("label", { class: "d-flex align-items-center fs-6 fw-bold form-label mb-2" }, [
                                _createElementVNode("span", null, "Select Address")
                              ], -1 /* HOISTED */)),
                              _createCommentVNode("end::Label"),
                              _createElementVNode("div", null, [
                                _withDirectives(_createElementVNode("select", {
                                  id: "dynamicSelectAddress",
                                  class: "form-select",
                                  "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.cardData.address_id) = $event)),
                                  onChange: _cache[17] || (_cache[17] = 
//@ts-ignore
(...args) => (_ctx.handleChange && _ctx.handleChange(...args))),
                                  name: "address_id"
                                }, [
                                  _cache[46] || (_cache[46] = _createElementVNode("option", { value: "" }, "Select Address", -1 /* HOISTED */)),
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.addressList, (option, index) => {
                                    return (_openBlock(), _createElementBlock("option", {
                                      key: index,
                                      value: option.address_id
                                    }, _toDisplayString(option.address_name), 9 /* TEXT, PROPS */, _hoisted_49))
                                  }), 128 /* KEYED_FRAGMENT */)),
                                  (!_ctx.cardData.address_id)
                                    ? (_openBlock(), _createElementBlock("option", _hoisted_50, "Add New Option..."))
                                    : _createCommentVNode("v-if", true)
                                ], 544 /* NEED_HYDRATION, NEED_PATCH */), [
                                  [_vModelSelect, _ctx.cardData.address_id]
                                ]),
                                (_ctx.addingOption)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_51, [
                                      _createElementVNode("div", _hoisted_52, [
                                        _withDirectives(_createElementVNode("input", {
                                          type: "text",
                                          class: "form-control mt-2",
                                          "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.newOption) = $event)),
                                          placeholder: "New Option"
                                        }, null, 512 /* NEED_PATCH */), [
                                          [_vModelText, _ctx.newOption]
                                        ])
                                      ]),
                                      _createElementVNode("div", _hoisted_53, [
                                        _createElementVNode("button", {
                                          type: "button",
                                          onClick: _cache[19] || (_cache[19] = 
//@ts-ignore
(...args) => (_ctx.addNewAddress && _ctx.addNewAddress(...args))),
                                          class: "form-control add-new-button mt-2"
                                        }, "Go")
                                      ])
                                    ]))
                                  : _createCommentVNode("v-if", true)
                              ])
                            ]))
                          : _createCommentVNode("v-if", true),
                        (_ctx.cardData.sensor_types_id == 2)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_54, [
                              _createCommentVNode("begin::Label"),
                              _cache[49] || (_cache[49] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "Fence Status", -1 /* HOISTED */)),
                              _createCommentVNode("end::Label"),
                              _createCommentVNode("begin::Select"),
                              _createVNode(_component_Field, {
                                name: "fence_status",
                                "data-control": "select2",
                                "data-hide-search": "true",
                                "data-placeholder": "Select Type",
                                class: "form-select border border-primary form-select-solid",
                                as: "select",
                                modelValue: _ctx.cardData.fence_status,
                                "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.cardData.fence_status) = $event))
                              }, {
                                default: _withCtx(() => _cache[48] || (_cache[48] = [
                                  _createElementVNode("option", { value: "" }, "Select Type", -1 /* HOISTED */),
                                  _createElementVNode("option", { value: "1" }, "Enable", -1 /* HOISTED */),
                                  _createElementVNode("option", { value: "0" }, "Disable", -1 /* HOISTED */)
                                ])),
                                _: 1 /* STABLE */
                              }, 8 /* PROPS */, ["modelValue"]),
                              _createElementVNode("div", _hoisted_55, [
                                _createElementVNode("div", _hoisted_56, [
                                  _createVNode(_component_ErrorMessage, { name: "fence_status" })
                                ])
                              ]),
                              _createCommentVNode("end::Select")
                            ]))
                          : _createCommentVNode("v-if", true),
                        (_ctx.cardData.sensor_types_id == 2)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_57, [
                              _createCommentVNode("begin::Label"),
                              _cache[51] || (_cache[51] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "Motion Status", -1 /* HOISTED */)),
                              _createCommentVNode("end::Label"),
                              _createCommentVNode("begin::Select"),
                              _createVNode(_component_Field, {
                                name: "motion_status",
                                "data-control": "select2",
                                "data-hide-search": "true",
                                "data-placeholder": "Select Type",
                                class: "form-select border border-primary form-select-solid",
                                as: "select",
                                modelValue: _ctx.cardData.motion_status,
                                "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.cardData.motion_status) = $event))
                              }, {
                                default: _withCtx(() => _cache[50] || (_cache[50] = [
                                  _createElementVNode("option", { value: "" }, "Select Type", -1 /* HOISTED */),
                                  _createElementVNode("option", { value: "1" }, "Enable", -1 /* HOISTED */),
                                  _createElementVNode("option", { value: "0" }, "Disable", -1 /* HOISTED */)
                                ])),
                                _: 1 /* STABLE */
                              }, 8 /* PROPS */, ["modelValue"]),
                              _createElementVNode("div", _hoisted_58, [
                                _createElementVNode("div", _hoisted_59, [
                                  _createVNode(_component_ErrorMessage, { name: "motion_status" })
                                ])
                              ]),
                              _createCommentVNode("end::Select")
                            ]))
                          : _createCommentVNode("v-if", true)
                      ]),
                      _createCommentVNode("end::Col")
                    ]),
                    _createCommentVNode("end::Row"),
                    _createCommentVNode(" </div> "),
                    _createCommentVNode("end::Col"),
                    _createCommentVNode(" </div> "),
                    _createCommentVNode("end::Input group"),
                    _createCommentVNode("begin::Actions"),
                    _createElementVNode("div", _hoisted_60, [
                      _createElementVNode("button", _hoisted_61, " Clear ", 512 /* NEED_PATCH */),
                      _createElementVNode("button", _hoisted_62, _cache[52] || (_cache[52] = [
                        _createElementVNode("span", { class: "indicator-label" }, " Submit ", -1 /* HOISTED */),
                        _createElementVNode("span", { class: "indicator-progress" }, [
                          _createTextVNode(" Please wait... "),
                          _createElementVNode("span", { class: "spinner-border spinner-border-sm align-middle ms-2" })
                        ], -1 /* HOISTED */)
                      ]), 512 /* NEED_PATCH */)
                    ]),
                    _createCommentVNode("end::Actions")
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["onSubmit", "validation-schema"]),
                _createCommentVNode("end::Form")
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_63, _cache[53] || (_cache[53] = [
                _createElementVNode("h5", { class: "text-danger p-10" }, "Please Select the Company first", -1 /* HOISTED */)
              ]))),
          _createCommentVNode("end::Modal body")
        ]),
        _createCommentVNode("end::Modal content")
      ]),
      _createCommentVNode("end::Modal dialog")
    ], 512 /* NEED_PATCH */),
    _createCommentVNode("end::Modal - New Card")
  ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
}