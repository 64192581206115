import { createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12 border-0" }
const _hoisted_3 = {
  class: "card",
  style: {"width":"100%","border":"0px"}
}
const _hoisted_4 = { class: "card-header" }
const _hoisted_5 = { class: "card-title" }
const _hoisted_6 = { class: "d-flex align-items-center position-relative my-1" }
const _hoisted_7 = { class: "search border border-primary rounded-2" }
const _hoisted_8 = { class: "svg-icon svg-icon-1 position-absolute" }
const _hoisted_9 = { class: "border border-primary rounded-2 ms-5" }
const _hoisted_10 = ["value"]
const _hoisted_11 = { class: "card-body pt-0" }
const _hoisted_12 = { class: "form-check form-check-sm form-check-custom form-check-solid" }
const _hoisted_13 = ["value", "onChange", "checked"]
const _hoisted_14 = { class: "" }
const _hoisted_15 = { style: {"padding-left":"3.5rem"} }
const _hoisted_16 = { style: {"padding-left":"3.5rem"} }
const _hoisted_17 = { class: "col-12" }
const _hoisted_18 = { class: "card" }
const _hoisted_19 = { class: "card-header border-0 d-flex justify-content-center" }
const _hoisted_20 = { class: "card-title" }
const _hoisted_21 = { class: "d-flex align-items-center position-relative my-1 text-center" }
const _hoisted_22 = { class: "border border-primary rounded-2" }
const _hoisted_23 = ["v-model"]
const _hoisted_24 = ["value"]
const _hoisted_25 = { class: "border border-primary rounded-2 ms-5" }
const _hoisted_26 = ["disabled"]
const _hoisted_27 = { class: "card-body pt-0" }
const _hoisted_28 = { style: {"padding-left":"3.5rem"} }
const _hoisted_29 = { style: {"padding-left":"3.5rem"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_KTDatatable = _resolveComponent("KTDatatable")!
  const _component_KTBinModal = _resolveComponent("KTBinModal")!
  const _component_KTKpiModal = _resolveComponent("KTKpiModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createCommentVNode("begin::Card"),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _cache[5] || (_cache[5] = _createElementVNode("h4", { class: "text-primary mx-10" }, "Send Devices To Platform", -1 /* HOISTED */)),
          _createCommentVNode("begin::Card header"),
          _createElementVNode("div", _hoisted_4, [
            _createCommentVNode("begin::Card title"),
            _createElementVNode("div", _hoisted_5, [
              _createCommentVNode("begin::Search"),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("span", _hoisted_8, [
                    _createVNode(_component_inline_svg, {
                      class: "iconmargin mt-1",
                      src: "/media/icons/duotune/general/gen021.svg"
                    })
                  ]),
                  _withDirectives(_createElementVNode("input", {
                    type: "text",
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
                    onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.searchItems())),
                    "data-kt-subscription-table-filter": "search",
                    class: "form-control form-control-solid w-560px ps-10",
                    placeholder: "Search comma separated values for exact device Ids (e.g. 002F53B2, 002F53FB)"
                  }, null, 544 /* NEED_HYDRATION, NEED_PATCH */), [
                    [_vModelText, _ctx.search]
                  ])
                ]),
                _createElementVNode("span", _hoisted_9, [
                  _createElementVNode("select", {
                    class: "form-select form-select-solid",
                    "data-control": "select2",
                    onChange: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onCustomerChange && _ctx.onCustomerChange(...args))),
                    "data-dropdown-parent": "#kt_modal_1",
                    "data-placeholder": "Select an option",
                    "data-allow-clear": "true"
                  }, [
                    _cache[4] || (_cache[4] = _createElementVNode("option", { value: "" }, "All", -1 /* HOISTED */)),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.customers, (customer, i) => {
                      return (_openBlock(), _createElementBlock("option", {
                        key: i,
                        value: customer.id
                      }, _toDisplayString(customer.name + " (ID: " + customer.id + ")"), 9 /* TEXT, PROPS */, _hoisted_10))
                    }), 128 /* KEYED_FRAGMENT */))
                  ], 32 /* NEED_HYDRATION */)
                ])
              ]),
              _createCommentVNode("end::Search")
            ]),
            _createCommentVNode("begin::Card title")
          ]),
          _createCommentVNode("end::Card header"),
          _createCommentVNode("begin::Card body"),
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_component_KTDatatable, {
              "table-data": _ctx.data,
              total: _ctx.total,
              "table-header": _ctx.headerConfig,
              "enable-items-per-page-dropdown": true,
              loading: _ctx.loading,
              checked: _ctx.checked,
              rowsPerPage: _ctx.pagination.page_size,
              onCurrentChange: _ctx.currentChange,
              onItemsPerPageChange: _ctx.itemsPerPageChange,
              onSelectAll: _ctx.selectAll
            }, {
              "cell-checkbox": _withCtx(({ row: device }) => [
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("input", {
                    class: "form-check-input border border-primary",
                    type: "checkbox",
                    value: device.variant_id,
                    onChange: ($event: any) => (_ctx.selectDevice(device)),
                    checked: _ctx.isChecked(device.variant_id)
                  }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_13)
                ])
              ]),
              "cell-variant_id": _withCtx(({ row: device }) => [
                _createElementVNode("div", null, _toDisplayString(device.variant_id), 1 /* TEXT */)
              ]),
              "cell-unique_random_number": _withCtx(({ row: device }) => [
                _createElementVNode("div", null, _toDisplayString(device.unique_random_number), 1 /* TEXT */)
              ]),
              "cell-variant": _withCtx(({ row: device }) => [
                _createElementVNode("div", _hoisted_14, _toDisplayString(device.variant), 1 /* TEXT */)
              ]),
              "cell-name": _withCtx(({ row: device }) => [
                _createTextVNode(_toDisplayString(device.name ? device.name + " (ID: " + device.id + ")" : "N/A"), 1 /* TEXT */)
              ]),
              "cell-pac_id": _withCtx(({ row: device }) => [
                _createElementVNode("div", null, _toDisplayString(device.pac_id), 1 /* TEXT */)
              ]),
              "cell-serial_number": _withCtx(({ row: device }) => [
                _createElementVNode("div", null, _toDisplayString(device.serial_number), 1 /* TEXT */)
              ]),
              "cell-api_doc_version": _withCtx(({ row: device }) => [
                _createElementVNode("span", _hoisted_15, _toDisplayString(device.api_doc_version), 1 /* TEXT */)
              ]),
              "cell-firmware_version": _withCtx(({ row: device }) => [
                _createElementVNode("span", _hoisted_16, _toDisplayString(device.firmware_version), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["table-data", "total", "table-header", "loading", "checked", "rowsPerPage", "onCurrentChange", "onItemsPerPageChange", "onSelectAll"])
          ]),
          _createCommentVNode("end::Card body")
        ])
      ])
    ]),
    _cache[7] || (_cache[7] = _createElementVNode("hr", { class: "mb-10" }, null, -1 /* HOISTED */)),
    _createElementVNode("div", {
      class: _normalizeClass(["row", !_ctx.showAddDeviceTable ? 'disablediv' : 'row'])
    }, [
      _createElementVNode("div", _hoisted_17, [
        _createElementVNode("div", _hoisted_18, [
          _createCommentVNode("begin::Card header"),
          _createElementVNode("div", _hoisted_19, [
            _createCommentVNode("begin::Card title"),
            _createElementVNode("div", _hoisted_20, [
              _createCommentVNode("begin::send to platform dropdown"),
              _createElementVNode("form", null, [
                _createElementVNode("div", _hoisted_21, [
                  _createElementVNode("span", _hoisted_22, [
                    _createElementVNode("select", {
                      class: "custom-select form-select form-select-solid form-control",
                      "data-control": "select2",
                      onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.selectCategory($event))),
                      "data-dropdown-parent": "#kt_modal_1",
                      "data-placeholder": "Select Customer to Add Device For it.",
                      "data-allow-clear": "true",
                      "v-model": _ctx.customer_id,
                      required: ""
                    }, [
                      _cache[6] || (_cache[6] = _createElementVNode("option", { value: "0" }, "Select Customer to Assign Device", -1 /* HOISTED */)),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.customers, (customer, i) => {
                        return (_openBlock(), _createElementBlock("option", {
                          key: i,
                          value: customer.id
                        }, _toDisplayString(customer.name + " (ID: " + customer.id + ")"), 9 /* TEXT, PROPS */, _hoisted_24))
                      }), 128 /* KEYED_FRAGMENT */))
                    ], 40 /* PROPS, NEED_HYDRATION */, _hoisted_23)
                  ]),
                  _createElementVNode("div", _hoisted_25, [
                    _createElementVNode("button", {
                      disabled: !_ctx.triggerthebutton,
                      type: "button",
                      class: "btn btn-sm btn-flex btn-primary",
                      "data-bs-toggle": "modal",
                      "data-bs-target": "#kt_modal_bin"
                    }, " ADD DEVICES TO PLATFORM ", 8 /* PROPS */, _hoisted_26)
                  ]),
                  _createCommentVNode(" <div class=\"border border-primary rounded-2 ms-5\">\r\n                  <button\r\n                    :disabled=\"!triggerthebutton\"\r\n                    type=\"button\"\r\n                    class=\"btn btn-sm btn-flex btn-primary\"\r\n                    data-bs-toggle=\"modal\"\r\n                    data-bs-target=\"#kt_modal_kpi\"\r\n                  >\r\n                    ADD BINs TO KPI\r\n                  </button>\r\n                </div> ")
                ])
              ]),
              _createCommentVNode("end::Search")
            ]),
            _createCommentVNode("begin::Card title")
          ]),
          _createCommentVNode("end::Card header"),
          _createCommentVNode("begin::Card body"),
          _createElementVNode("div", _hoisted_27, [
            _createVNode(_component_KTDatatable, {
              "table-data": _ctx.add_devices,
              "table-header": _ctx.addDeviceHeader,
              total: _ctx.add_devices.length,
              rowsPerPage: _ctx.pagination.page_size,
              "enable-items-per-page-dropdown": true
            }, {
              "cell-variant_id": _withCtx(({ row: device }) => [
                _createElementVNode("div", null, _toDisplayString(device.variant_id), 1 /* TEXT */)
              ]),
              "cell-unique_random_number": _withCtx(({ row: device }) => [
                _createElementVNode("div", null, _toDisplayString(device.unique_random_number), 1 /* TEXT */)
              ]),
              "cell-variant": _withCtx(({ row: device }) => [
                _createElementVNode("div", null, _toDisplayString(device.variant), 1 /* TEXT */)
              ]),
              "cell-name": _withCtx(({ row: device }) => [
                _createTextVNode(_toDisplayString(device.name ? device.name + " (ID: " + device.id + ")" : "N/A"), 1 /* TEXT */)
              ]),
              "cell-pac_id": _withCtx(({ row: device }) => [
                _createElementVNode("div", null, _toDisplayString(device.pac_id), 1 /* TEXT */)
              ]),
              "cell-serial_number": _withCtx(({ row: device }) => [
                _createElementVNode("div", null, _toDisplayString(device.serial_number), 1 /* TEXT */)
              ]),
              "cell-api_doc_version": _withCtx(({ row: device }) => [
                _createElementVNode("span", _hoisted_28, _toDisplayString(device.api_doc_version), 1 /* TEXT */)
              ]),
              "cell-firmware_version": _withCtx(({ row: device }) => [
                _createElementVNode("span", _hoisted_29, _toDisplayString(device.firmware_version), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["table-data", "table-header", "total", "rowsPerPage"])
          ]),
          _createCommentVNode("end::Card body")
        ])
      ])
    ], 2 /* CLASS */),
    _createVNode(_component_KTBinModal, {
      onReload: _ctx.loadData,
      devices: _ctx.add_devices,
      customer_id: _ctx.customer_id
    }, null, 8 /* PROPS */, ["onReload", "devices", "customer_id"]),
    _createCommentVNode("end::Card"),
    _createCommentVNode(" KPI Modal "),
    _createVNode(_component_KTKpiModal, {
      onReload: _ctx.loadData,
      devices: _ctx.add_devices,
      customer_id: _ctx.customer_id
    }, null, 8 /* PROPS */, ["onReload", "devices", "customer_id"])
  ], 64 /* STABLE_FRAGMENT */))
}