
import { defineComponent, onMounted, ref, watch,computed } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { getWasteType, getGroupList, getCompany, AddAddressGroup, getAddressList, AddAddress } from "@/store/api/devices";
import { forkJoin } from "rxjs";
import * as _ from "lodash";
import * as Yup from "yup";
import { addRepoToPlatform } from "@/store/api/devices";
import Swal from "sweetalert2/dist/sweetalert2.js";

interface CardData {
  nameOnCard: string;
  name_prefix: string;
  bin_height: string;
  expirationYear: string;
  cvv: string;
}

export default defineComponent({
  name: "bin-modal",
  emit: ["reload"],
  props: {
    devices: { type: Array },
    customer_id: { type: String },
  },
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  setup(props, { emit }) {
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const binModalRef = ref<null | HTMLElement>(null);
    const wasteType = ref<any>([]);
    const groupList = ref<any>([]);
    let addressList = ref<any>([]);
    let companyData = ref<any>([]);
    let checkKPI = ref<boolean>(false);

    const addToPlatformResp = ref<any>({
      success: false,
      data: {
        added: [],
        errors: [],
        skipped: [],
      },
    });
    const devicesList = ref(props.devices);

    const options = ref(['Option 1', 'Option 2', 'Option 3']); // Initial options

    const newOption = ref('');
    const newGroup = ref('');
    const addingOption = ref(false);
    const addingGroupOption = ref(false);
    let newOptionAdded = false;
    let newGroupAdded = false;

    const customer_id = ref(props.customer_id);
    let response: any = {};
    const cardData = ref({
      name_prefix: null,
      bin_height: null,
      address: "",
      lat: null,
      lng: null,
      waste_type_id: null,
      pickup_threshold: null,
      group_id: "",
      address_id: "",
      weight_per_commodity: "",
      bin_capacity: "",
      bin_type: "",
      sensor_types_id: 1,
      battery: null,
      default_radius: null,
      gps_validity: 0,
      fence_status: 0,
      motion_status: 0,
    });
    onMounted(async () => {
      wasteType.value = await getWasteType().then((res) => res.data.data);
    });

    const validationSchema = Yup.object().shape({
      sensor_types_id: Yup.number().required().label("Sensor Type").nullable(),
      name_prefix: Yup.string().required().label("Name").nullable(),
      bin_height: Yup.number().required('Bin height is required').typeError("Bin height must be a number").min(0, 'Bin height must be greater than 0').max(500, 'Bin height must be less than or equal to 500').label('Bin Height(cm)').nullable(),
      address: Yup.string().required().label("Address is required").nullable(),
      lat: Yup.number().required().label("Latitude").nullable(),
      lng: Yup.number().required().label("Longitude").nullable(),
      waste_type_id: Yup.string().required().label("Waste Type").nullable(),
      pickup_threshold: Yup.number().nullable().required("Pickup Threshold is required").typeError("Pickup Threshold must be a number").max(100, "The pickup threshold may not be greater than 100"),
      weight_per_commodity: Yup.string().optional().label("Weight Per Commodity").nullable(),
      bin_capacity: Yup.string().optional().label("Bin Capacity").nullable(),
      bin_type: Yup.string().optional().label("Bin Type").nullable(),
      group_id: Yup.string().optional().label("Address Group").nullable(),
      address_id: Yup.string().optional().label("Select Address").nullable(),
    });
    const validationSchemaTrack = Yup.object().shape({
      sensor_types_id: Yup.number().required().label("Sensor Type").nullable(),
      name_prefix: Yup.string().required().label("Name").nullable(),
      lat: Yup.number().required().label("Latitude").nullable(),
      lng: Yup.number().required().label("Longitude").nullable(),
      battery: Yup.number().required().label("Battery is required").nullable(),
      default_radius: Yup.number().required().label("Default Radius is required").nullable(),
      fence_status: Yup.number().required().label("Fence Status is required").nullable(),
      gps_validity: Yup.number().required().label("GPS Validity is required").nullable(),
      motion_status: Yup.number().required().label("Motion Status is required").nullable(),
    });

    const validationSchemaDevices = Yup.object().shape({
      sensor_types_id: Yup.number().required().label("Sensor Type").nullable(),
      name_prefix: Yup.string().required().label("Name").nullable(),
      // default_radius: Yup.number().required().label("Default Radius is required").nullable(),
      // gps_validity: Yup.number().required().label("GPS Validity is required").nullable(),
      // fence_status: Yup.number().required().label("Fence Status is required").nullable(),
      // motion_status: Yup.number().required().label("Motion Status is required").nullable(),
      address: Yup.string().required().label("Address is required").nullable(),
      lat: Yup.number().required().label("Latitude").nullable(),
      lng: Yup.number().required().label("Longitude").nullable(),
      battery: Yup.number().required().label("Battery is required").nullable()
    });

    watch(
      () => props.customer_id,
      (first) => {
        customer_id.value = first;
        // discardModal()
      }
    );
    const fetchData = async (newValue) => {
      try {
        companyData.value = {};
        const response = await getCompany(newValue).then((res) => {
          companyData.value = res;
          console.log("Response in fetch Function", res);
          res
        });
        checkKPI.value = companyData.value.is_kpi;
        console.log("Check Data ", checkKPI.value);
      } catch (error) {
        console.error("Error fetching company data:", error);
      }
    };

    watch(
      () => props.customer_id,
      async (newValue, oldValue) => {
        console.log("data", newValue);
        await fetchData(newValue);
        groupList.value = await getGroupList({ customer_id: newValue }).then((res) => res.data);
        console.log("Group List Values ", groupList.value);
      },
      { immediate: true }
    );

    const groupHandleChange = async () => {
      if (cardData.value.group_id === 'add_new') {
        if (!newGroupAdded) {
          addingGroupOption.value = true;
        } else {
          cardData.value.group_id = '';
        }
      } else {
        addressList.value = await getAddressList({ group_id: cardData.value.group_id }).then((res) => res.data);

        addingGroupOption.value = false;
      }
    };

const xyz = computed(() => {
  console.log("**", cardData.value.sensor_types_id);
  if (cardData.value.sensor_types_id == 1) {
    return validationSchema;
  } else if (cardData.value.sensor_types_id == 2) {
    return validationSchemaTrack;
  } else {
    return validationSchemaDevices;
  }
});
    const addNewGroup = async () => {
      if (newGroup.value.trim() !== '') {
        let payload = {
          group_name: newGroup.value,
          se_account_id: customer_id.value
        };
        console.log("New Option Payload", payload);
        let groupResponse = await AddAddressGroup(payload).then((res) => res);
        console.log("Group Add API Response", groupResponse);

        groupList.value.push({ group_name: newGroup.value, group_id: groupResponse.id });
        cardData.value.group_id = groupResponse.id; // Select the newly added option
        newGroup.value = ''; // Clear input
        addingGroupOption.value = false; // Hide input
        newGroupAdded = true;
      }
    };

    const handleChange = () => {
      if (cardData.value.address_id === 'add_new') {
        if (!newOptionAdded) {
          addingOption.value = true;
        } else {
          cardData.value.address_id = '';
        }
      } else {
        addingOption.value = false;
      }
    };

    const addNewAddress = async () => {
      if (newOption.value.trim() !== '') {
        let payload = {
          address_name: newOption.value,
          group_id: cardData.value.group_id,
          lat: cardData.value.lat,
          lng: cardData.value.lng
        };
        let addressResponse = await AddAddress(payload).then((res) => res);
        addressList.value.push({ address_id: addressResponse.id, address_name: addressResponse.name, group_name: "" });
        cardData.value.address_id = addressResponse.id; // Select the newly added option
        newOption.value = ''; // Clear input
        addingOption.value = false; // Hide input
        newOptionAdded = true;
      }
    };

    const submit = () => {
  if (!submitButtonRef.value) {
    return;
  }

  // Show loading indicator
  submitButtonRef.value.setAttribute("data-kt-indicator", "on");
  submitButtonRef.value.disabled = true; // Disable button while submitting

  let json = {
    customer_id: customer_id.value,
    devices: devicesList.value,
    detail: cardData.value,
  };

  let chunk = [...createChunks(json.devices, 50)];
  let ajax_calls = ref<any[]>([]);

  for (var i = 0; i < chunk.length; i++) {
    ajax_calls.value.push(
      addRepoToPlatform({
        devices: chunk[i],
        customer_id: json.customer_id,
        details: json.detail,
      })
    );
  }

  forkJoin(ajax_calls.value).subscribe(
    (data) => {
      for (var i = 0; i < chunk.length; i++) {
        addToPlatformResp.value = _.mergeWith(response, data[i], joinResults);
      }
      response = {};

      if (submitButtonRef.value) {
        submitButtonRef.value.disabled = false; // ✅ Re-enable button after submission
        submitButtonRef.value?.removeAttribute("data-kt-indicator");
      }

      // Reset form fields
      // cardData.value = {
      //   sensor_types_id: "",
      //   name_prefix: "",
      //   address: "",
      //   default_radius: "",
      // };

      emit("reload");
    },
    (err) => {
      console.log(err);
      if (submitButtonRef.value) {
        submitButtonRef.value.disabled = false; // ✅ Ensure button re-enables even on error
        submitButtonRef.value?.removeAttribute("data-kt-indicator");
      }
      addToPlatformResp.value = err;
    }
  );
};

    const discardModal = () => {
      document.getElementById("kt_modal_bin_cancel")?.click();
    };

    const isObject = (val: boolean) => {
      return typeof val === "object";
    };

    const hideAlert = () => {
      addToPlatformResp.value = undefined;
    };

    return {
      cardData,
      validationSchema,
      validationSchemaTrack,
      validationSchemaDevices,
      xyz,
      submit,
      submitButtonRef,
      binModalRef,
      isObject,
      addToPlatformResp,
      hideAlert,
      discardModal,
      wasteType,
      options,
      newOption,
      addingOption,
      handleChange,
      addNewAddress,
      newOptionAdded,
      newGroupAdded,
      newGroup,
      addingGroupOption,
      groupHandleChange,
      addNewGroup,
      groupList,
      checkKPI,
      addressList
    };
  },
});

function* createChunks(arr, n) {
  for (let i = 0; i < arr.length; i += n) {
    yield arr.slice(i, i + n);
  }
}

function joinResults(objValue, srcValue) {
  if (_.isArray(objValue)) {
    return objValue.concat(srcValue);
  }
}
